const ticketPriceAmount = 1500;

function getTicketPriceForEvent() {
    return getTicketPriceFromAmount(ticketPriceAmount);
}

function getTicketPriceFromAmount(amount) {
    return {
        price: amount,
        priceFormatted: `$${(amount / 100).toFixed(2)}`
    }
}

export const pricingManager = {
    getTicketPriceForEvent,
    getTicketPriceFromAmount
};