import React from 'react';
import jsonp from "jsonp";
import { Button, Modal, Input, Message, Segment } from 'semantic-ui-react';

const DEFAULT_ERROR = 'Sorry, we could not send your email. Please try again.';

class MailchimpModal extends React.Component {
    state = {
        emailAddress: null,
        error: null,
        processing: false
    };

    setErrorStates = (errorMessage) => {
        this.setState({ processing: false, error: errorMessage || null });
    };

    handleSubmit = () => {
        const { emailAddress } = this.state;
        const action = 'https://gmail.us4.list-manage.com/subscribe/post-json?u=03a1d6d17a5c4f8131877a9e1&amp;id=47e6d77aa4';
        const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;

        if (!regex.test(emailAddress)) {
            this.setErrorStates('A valid email address is required');
            return;
        }

        try {
            const url = `${action}&EMAIL=${emailAddress}`;
            this.sendData(url);
        }
        catch (error) {
            this.setErrorStates(DEFAULT_ERROR);
        }
    };

    sendData = (url) => {
        const { onClose } = this.props;
        this.setState({ processing: true });

        jsonp(url, { param: "c" }, (err, data) => {
            if (data.msg.includes("already subscribed")) {
                this.setErrorStates('It looks like we already have your email address. Great!');
            } else if (err) {
                this.setErrorStates(DEFAULT_ERROR);
            } else if (data.result !== 'success') {
                this.setErrorStates(DEFAULT_ERROR);
            } else {
                onClose();
            };
        });
    };

    handleEmailInputChange = async (event, data) => {
        this.setState({ emailAddress: data.value, error: '' });
    };

    render() {
        const { onClose } = this.props;
        const { processing, emailAddress, error } = this.state;

        return (
            <Modal open={true} size='tiny'>
                <Modal.Header>Remind Me</Modal.Header>
                <Modal.Content>
                    <p>You will be added to our email list for event updates, news and special offers.</p>

                    <Message error content={error} hidden={!(error && error !== '')} />

                    <Input size='large'
                        placeholder="Email"
                        fluid
                        type="text"
                        id="email"
                        name="email"
                        onChange={this.handleEmailInputChange}
                        value={emailAddress || ''} />

                    <Button
                        fluid
                        onClick={this.handleSubmit}
                        loading={processing}
                        disabled={processing}
                        style={{ marginTop: "3em" }}
                        color='black'>Remind Me!</Button>

                    <Segment basic textAlign='center' style={{ marginTop: "0em" }}>
                        <span onClick={onClose} style={{ cursor: "pointer" }}>Close</span>
                    </Segment>
                </Modal.Content>
            </Modal>
        );
    };
}

export default MailchimpModal;