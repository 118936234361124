import React from 'react';
import { Segment, Container, Header } from 'semantic-ui-react';
import NavBar from "./NavBar";

import './MiniMasthead.css';

export default props => (
  <React.Fragment>
    <Segment inverted vertical textAlign='center' className="MiniMasthead-background">
      <NavBar {...props} />
      <Container text className="MiniMasthead-container">
        <Header inverted as="h1" content={props.text} size="huge" />
      </Container>
    </Segment>
  </React.Fragment>
);
