import { storageManager } from './storageManager';
import { authenticationProvider } from './netlifyAuthProvider';

const queryString = require('query-string');
const referringPartnerQueryParam = 'vip';
const referringPartnerStorageKey = 'referring_partner';

function parseReferringPartnerQueryString() {
    const parsed = queryString.parse(window.location.search);

    if (!parsed || !parsed[referringPartnerQueryParam]) {
        return null;
    }

    return parsed[referringPartnerQueryParam];
}

function getReferringPartnerStored() {
    const currentUser = authenticationProvider.currentUser();

    if (currentUser && currentUser.user_metadata && currentUser.user_metadata.referring_partner) {
        return currentUser.user_metadata.referring_partner;
    }

    return null;
}

function getReferringPartnerSession() {
    const partnerName = storageManager.getItem(referringPartnerStorageKey);
    return partnerName;
}

function getReferringPartner() {
    const partnerName = getReferringPartnerSession();

    if (partnerName) {
        return partnerName;
    }

    return getReferringPartnerStored();
}

function setReferringPartnerSession() {
    const partnerName = parseReferringPartnerQueryString();

    if (!partnerName) {
        return;
    }

    storageManager.setItem(referringPartnerStorageKey, partnerName);
}

function clearReferringPartnerSession() {
    storageManager.removeItem(referringPartnerStorageKey);
}

export const partnerManager = {
    setReferringPartnerSession,
    clearReferringPartnerSession,
    getReferringPartner
};