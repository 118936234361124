import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import netlifyIdentity from 'netlify-identity-widget';
import 'semantic-ui-css/semantic.min.css'
import ScrollToTop from './components/ScrollToTop';

import App from './App';

const rootElement = document.getElementById('root');

const app = (
  <Router>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>
);

window.netlifyIdentity = netlifyIdentity;
netlifyIdentity.init({logo: false});

ReactDOM.render(app, rootElement);