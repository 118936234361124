import React, { useEffect } from 'react';
import {
    Grid,
    Statistic,
    Segment,
    Label
} from 'semantic-ui-react';
import Countdown from 'react-countdown';

import './StreamCountdown.css';

export default function StreamCountdown({ nextEventWindow, onCompletion, trackingAgent }) {
    const { startTimeUtc, startTimeLocation, startTimeDescription } = nextEventWindow;

    useEffect(() => {
        trackingAgent.track('countdown-view', { startTimeLocation, startTimeDescription });
    });

    // If this isn't a restream and we have a name, announce the name
    const announcement = nextEventWindow.restreams && nextEventWindow.streamNames
        ? nextEventWindow.streamNames[0]
        : null;

    const ribbon = announcement ?
        <Label color='orange' ribbon style={{ }} className="StreamCountdown-ribbon">
            {announcement}
        </Label> : null;

    const renderer = ({ days, hours, minutes, seconds }) => {
        return (
            <Segment placeholder raised style={{ marginTop: "-45px", maxWidth: "820px" }}>
                {ribbon}
                <Grid stackable columns={4}>
                    <Grid.Column>
                        <Statistic size='huge'>
                            <Statistic.Value>{days}</Statistic.Value>
                            <Statistic.Label>Days</Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size='huge'>
                            <Statistic.Value>{hours}</Statistic.Value>
                            <Statistic.Label>Hours</Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size='huge'>
                            <Statistic.Value>{minutes}</Statistic.Value>
                            <Statistic.Label>Minutes</Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic size='huge'>
                            <Statistic.Value>{seconds}</Statistic.Value>
                            <Statistic.Label>Seconds</Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                </Grid>
                <Label attached='bottom'>time until next showtime</Label>
            </Segment>
        )
    };

    return (
        <React.Fragment>
            <Countdown date={startTimeUtc} renderer={renderer} onComplete={onCompletion}></Countdown>
        </React.Fragment>
    );
}