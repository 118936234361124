import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Footer from "./Footer";

export default ({ component: C, props: cProps, ...rest }) => (
    <React.Fragment>
        <div className="App-content">
            <Route
                {...rest}
                render={props =>
                    cProps.authProvider.isAuthenticated() ? (
                        <C {...props} {...cProps} />
                    ) : (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: props.location }
                                }}
                            />
                        )
                }
            />
        </div>
        <Footer />
    </React.Fragment>
);