import React from 'react';
import AppliedRoute from './AppliedRoute';
import Footer from "./Footer";

export default (props) => (
    <React.Fragment>
        <div className="App-content">
            <AppliedRoute {...props} />
        </div>
        <Footer />
    </React.Fragment>
);
