import axios from 'axios';
import axiosRetry from 'axios-retry';
import { authenticationProvider } from './netlifyAuthProvider';

axios.defaults.baseURL = '/.netlify/functions/';
axios.defaults.timeout = 10000; // 10 seconds

axios.interceptors.request.use(async (config) => {
    if (config.useAuthentication && authenticationProvider.isAuthenticated()){
        // this will use an existing token if not expired
        await authenticationProvider.refresh();
        const authorizationToken = authenticationProvider.getAuthToken();

        if (authorizationToken) {
            config.headers['Authorization'] = `Bearer ${authorizationToken}`;
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosRetry(axios, {
    retries: 3,
    retryCondition: (error) => {
        // if retry condition is not specified, by default only idempotent requests are retried
        return error.code === 'ECONNABORTED' || (error.response.status >= 500 && error.response.status <= 599);
    },
    retryDelay: (retryCount) => {
        return retryCount * 3000;
    },
    shouldResetTimeout: true
});

async function getPublicEventByUrlSlug(urlSlug) {
    const getFunction = () => axios.get(`get-event-by-urlslug/${urlSlug}`);
    return await callFunctionApi(getFunction);
}

async function getAuthorizedEventByEventId(eventId, accessKey) {
    const config = { useAuthentication: true };

    if (accessKey) {
        config.headers = {
            access: accessKey
        };
    }

    const getFunction = () => axios.get(`get-authorized-event-by-eventid/${eventId}`, config);
    return await callFunctionApi(getFunction);
}

async function getStreamPreviewUrl(streamName) {
    const getFunction = () => axios.get(`get-stream-preview-url/${streamName}`, {useAuthentication: true});
    return await callFunctionApi(getFunction);
}

async function createStripePaymentIntent(eventId, partnerName) {
    const payload = {
        idempotencyKey: newGuid()
    };

    if (partnerName) {
        payload.partnerName = partnerName;
    }

    const createFunction = () => axios.post(`create-payment-intent/${eventId}`, payload, {useAuthentication: true});
    return await callFunctionApi(createFunction);
}

async function setPartnerEmailOptin(paymentId) {
    const setFunction = () => axios.post(`set-partner-optin/${paymentId}`, null, {useAuthentication: true});
    return await callFunctionApi(setFunction);
}

async function createPendingAuthorization(eventId) {
    const createFunction = () => axios.post(`create-pending-authorization/${eventId}`, null, {useAuthentication: true});
    return await callFunctionApi(createFunction);
}

async function callFunctionApi(getFunction) {
    try {  
        const response = await getFunction();
        return response.data;
    } catch (error) {
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }

        throw error;
    }
}

function newGuid() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

export const netlifyApiProvider = {
    getPublicEventByUrlSlug,
    getAuthorizedEventByEventId,
    createPendingAuthorization,
    createStripePaymentIntent,
    setPartnerEmailOptin,
    getStreamPreviewUrl
};