import React from 'react';
import { Link } from 'react-router-dom';
import {
    Segment,
    Container,
    Grid,
    List,
    Icon,
    Header
} from 'semantic-ui-react';

import './Footer.css';

export default props => (
    <Segment vertical className={props.condensed ? "Footer-condensed" : null}>
        <div className="Footer">
            <Container>
                <Grid divided stackable>
                    <Grid.Row centered>
                        <Grid.Column width={2}>
                            <div className="Footer-margins">
                                <Header as="h4" content="Site" />
                                <List link>
                                    <List.Item>
                                        <Link to="/about">About</Link>
                                    </List.Item>
                                    <List.Item>
                                        <Link to="/faqs">FAQs</Link>
                                    </List.Item>
                                    <List.Item>
                                        <Link to="/terms">Terms</Link>
                                    </List.Item>
                                </List>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <div className="Footer-margins">
                                <Header as="h4">Find Us</Header>
                                <p>
                                    <a
                                        href="https://www.facebook.com/RudyVanGelderStudio"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Icon size="big" name="facebook" />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/RudyVanGelderStudio"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Icon size="big" name="instagram" />
                                    </a>
                                </p>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <div className="Footer-margins">
                                <p>Copyright &copy; 2021 RVG Productions, LLC. All Rights Reserved.</p>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    </Segment>
);
