const persistentCallbacks = {};
const oneTimeCallbacks = {};

function triggerCallbacks(callbacks, eventName) {
    const callbackMap = callbacks[eventName] || new Set();
    Array.from(callbackMap.values()).forEach((callback) => {
        callback.apply(callback, Array.prototype.slice.call(arguments, 2));
    });
}

function trigger(eventName) {
    triggerCallbacks(persistentCallbacks, eventName);
    
    if (oneTimeCallbacks[eventName]) {
        triggerCallbacks(oneTimeCallbacks, eventName);
        oneTimeCallbacks[eventName].clear();
    }
}

function on(eventName, cb) {
    persistentCallbacks[eventName] = persistentCallbacks[eventName] || new Set();
    persistentCallbacks[eventName].add(cb);
};

function off(eventName, cb) {
    if (persistentCallbacks[eventName]) {
        persistentCallbacks[eventName].delete(cb);
    }
};

function once(eventName, cb) {
    oneTimeCallbacks[eventName] = oneTimeCallbacks[eventName] || new Set();
    oneTimeCallbacks[eventName].add(cb);
};

export const callbackManager = {
    trigger,
    on,
    off,
    once
};