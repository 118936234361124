import netlifyIdentity from 'netlify-identity-widget';
import { callbackManager } from './callbackManager';
import { partnerManager } from './partnerManager';

const eventNames = {
    login: 'login',
    signup: 'signup',
    close: 'close',
    logout: 'logout',
    update: 'update'
};

let trackingAgent = null;

netlifyIdentity.on(eventNames.signup, (user) => {
    authenticatedCallback(user, () => {
        callbackManager.trigger(eventNames.signup);
        callbackManager.trigger(eventNames.login);
        callbackManager.trigger(eventNames.update);
    });
});

netlifyIdentity.on(eventNames.login, (user) => {
    authenticatedCallback(user, () => {
        callbackManager.trigger(eventNames.login);
        callbackManager.trigger(eventNames.signup);
        callbackManager.trigger(eventNames.update);
    });
});

netlifyIdentity.on(eventNames.close, () => {
    callbackManager.trigger(eventNames.close);
    callbackManager.trigger(eventNames.update);
});

netlifyIdentity.on(eventNames.logout, () => {
    callbackManager.trigger(eventNames.logout);
    callbackManager.trigger(eventNames.update);
});

function authenticatedCallback(user, onComplete) {
    netlifyIdentity.close();

    if (trackingAgent) {
        trackingAgent.identify(user.id);
        trackingAgent.addUserProperties({ 'email': user.email });
    }

    const partnerName = partnerManager.getReferringPartner();

    if (!partnerName) {
        onComplete();
        return;
    }

    if (!user.user_metadata || !user.user_metadata.referring_partner) {
        onComplete();
        return;
    }

    const referringPartner = user.user_metadata.referring_partner;

    if (partnerName === referringPartner) {
        onComplete();
        return;
    }

    netlifyIdentity.gotrue.currentUser().update({
        data: {
            referring_partner: partnerName
        }
    }).then(() => {
        partnerManager.clearReferringPartnerSession();
        onComplete();
    });
}

function login(callback) {
    if (callback) {
        callbackManager.once(eventNames.login, callback);
    }
    netlifyIdentity.open(eventNames.login);
}

function signup(callback) {
    if (callback) {
        callbackManager.once(eventNames.signup, callback);
    }
    netlifyIdentity.open(eventNames.signup);
}

function signout(callback) {
    if (callback) {
        callbackManager.once(eventNames.logout, callback);
    }
    if (trackingAgent) {
        trackingAgent.resetIdentity();
    }
    netlifyIdentity.logout();
}

function setCallback(callback) {
    callbackManager.on(eventNames.update, callback);
}

function clearCallback(callback) {
    callbackManager.off(eventNames.update, callback);
}

function setTracking(tracking) {
    trackingAgent = tracking;
}

function isAuthenticated() {
    return (netlifyIdentity.currentUser() !== null);
}

function currentUser() {
    return netlifyIdentity.currentUser();
}

async function refresh() {
    // without the force = true param, this will use an existing token
    // if not expired
    await netlifyIdentity.refresh();
}

function getAuthToken() {
    const currentUser = netlifyIdentity.currentUser();

    if (!currentUser || !currentUser.token || !currentUser.token.access_token) {
        return null;
    }

    return currentUser.token.access_token;
}

export const authenticationProvider = {
    isAuthenticated,
    login,
    signup,
    signout,
    refresh,
    getAuthToken,
    setCallback,
    clearCallback,
    currentUser,
    setTracking
};