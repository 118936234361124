import React from "react";
import {
    CardElement,
    PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import {
    Form,
    Message,
    Button,
    Modal,
    Header,
    Divider,
    Input,
    Grid
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { pricingManager } from '../libs/pricingManager';
import { partnerManager } from '../libs/partnerManager';

import "./CheckoutModal.css";

const CARD_OPTIONS = {
    style: {
        base: {
            color: "#000",
            fontFamily: "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "18px",
            "::placeholder": {
                color: "#868e96"
            }
        },
        invalid: {
            color: "#9e2146",
            iconColor: "#9e2146"
        }
    },
    hidePostalCode: true
};

class CheckoutModal extends React.Component {
    state = {
        succeeded: false,
        error: null,
        isFatalError: false,
        processing: false,
        disabled: true,
        clientSecret: null,
        paymentRequest: null,
        cardHolderFullName: null,
        cardHolderEmail: null
    };

    setClientSecret = async () => {
        const { functionsApiProvider, currentEvent, trackingAgent } = this.props;

        try {
            const partnerName = partnerManager.getReferringPartner();
            const response = await functionsApiProvider.createStripePaymentIntent(currentEvent.eventId, partnerName);
            this.setState({ clientSecret: response.client_secret });
        } catch (error) {
            this.setDefaultErrorStates(error, true);
            trackingAgent.track('payment-intent-failed', error);
        }
    };

    setPaymentRequest = async () => {
        const { stripe, trackingAgent } = this.props;
        const { ticketPrice } = this.state;

        if (!stripe || !ticketPrice) {
            return;
        }

        try {
            await stripe;
            const paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Live from Van Gelder Studio',
                    amount: ticketPrice.price,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            const result = await paymentRequest.canMakePayment();

            if (result) {
                this.setState({ paymentRequest }, () => {
                    this.setPaymentMethodCallbacks();
                });
            }
        }
        catch (error) {
            this.setDefaultErrorStates(error);
            trackingAgent.track('payment-method-failed', error);
        }
    };

    setCardholdDetails = () => {
        const { authProvider } = this.props;

        const currentUser = authProvider.currentUser();
        const fullName = (currentUser.user_metadata && currentUser.user_metadata.full_name)
            ? currentUser.user_metadata.full_name
            : null;
        const email = currentUser.email;

        this.setState({ cardHolderFullName: fullName, cardHolderEmail: email });
    };

    setTicketPrice = () => {
        const ticketPrice = pricingManager.getTicketPriceForEvent();
        this.setState({ ticketPrice });
    }

    handleCardElementChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        this.setState({ disabled: event.empty, error: event.error ? event.error.message : '' });
    };

    handleFullNameChange = async (event, data) => {
        this.setState({ cardHolderFullName: data.value, disabled: false, error: '' });
    };

    handleEmailChange = async (event, data) => {
        this.setState({ cardHolderEmail: data.value, disabled: false, error: '' });
    };

    handleEmailListOptInChange = () => {
        this.setState((prevState) => ({ emailListOptInChecked: !prevState.emailListOptInChecked }));
    }

    getCardHolderContactDetailsError = () => {
        const {
            cardHolderFullName,
            cardHolderEmail
        } = this.state;

        if (!cardHolderFullName) {
            return 'Full name is required';
        }

        if (!cardHolderEmail) {
            return 'Email is required';
        }

        return null;
    };

    setSucceededStates = () => {
        const { onPaymentComplete } = this.props;
        this.setState({ succeeded: true, processing: false, error: null, isFatalError: false }, () => {onPaymentComplete()});
    };

    setDefaultErrorStates = (error, isFatal) => {
        this.setErrorStates(error.message || 'There was an error requesting available payment methods', isFatal);
    };

    setErrorStates = (errorMessage, isFatal) => {
        const status = { succeeded: false, processing: false, error: errorMessage || null };

        if (isFatal === true) {
            status.isFatalError = true;
        }

        this.setState(status);
    };

    handlePaymentMethodReceived = async (ev) => {
        const { stripe, trackingAgent, functionsApiProvider, currentEvent } = this.props;
        const { clientSecret } = this.state;

        this.setState({ processing: true });

        try {
            await functionsApiProvider.createPendingAuthorization(currentEvent.eventId);

            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
            );

            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
                this.setErrorStates(`Payment failed. ${confirmError.message}`);
                trackingAgent.track('payment-request-failed', confirmError);
                return;
            }

            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');

            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow.
            if (paymentIntent.status === "requires_action") {
                // Let Stripe.js handle the rest of the payment flow.
                const { error } = await stripe.confirmCardPayment(clientSecret);

                if (error) {
                    this.setErrorStates(`Payment failed. ${error.message}`);
                    trackingAgent.track('payment-request-failed', error);
                    return;
                }
            }

            this.setSucceededStates();
        } catch (error) {
            this.setDefaultErrorStates(error);
            trackingAgent.track('payment-request-failed', error);
        }
    };

    handlePayWithCardSubmit = async (ev) => {
        const { stripe, elements, trackingAgent, functionsApiProvider, currentEvent } = this.props;
        const {
            cardHolderFullName,
            cardHolderEmail,
            clientSecret
        } = this.state;

        ev.preventDefault();

        const cardHolderContactDetailsError = this.getCardHolderContactDetailsError();
        if (cardHolderContactDetailsError) {
            this.setState({ error: cardHolderContactDetailsError });
            return;
        };

        this.setState({ processing: true });

        try {
            await functionsApiProvider.createPendingAuthorization(currentEvent.eventId);

            const payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: cardHolderFullName,
                        email: cardHolderEmail
                    }
                }
            });

            if (payload.error) {
                this.setErrorStates(`Payment failed. ${payload.error.message}`);
                trackingAgent.track('card-payment-failed', payload.error);
                return;
            }

            this.setSucceededStates();
        } catch (error) {
            this.setDefaultErrorStates(error);
            trackingAgent.track('card-payment-failed', error);
        }
    };

    setPaymentMethodCallbacks = () => {
        const { paymentRequest } = this.state;

        if (paymentRequest) {
            paymentRequest.on("paymentmethod", this.handlePaymentMethodReceived);

            paymentRequest.on('cancel', () => {
                paymentRequest.off('paymentmethod');
            });
        };
    }

    clearPaymentMethodCallbacks = () => {
        const { paymentRequest } = this.state;

        if (paymentRequest) {
            paymentRequest.off("paymentmethod");
            paymentRequest.off("cancel");
        }
    }

    async componentDidMount() {
        const { trackingAgent } = this.props;

        this.setCardholdDetails();
        this.setTicketPrice();
        await this.setClientSecret();
        await this.setPaymentRequest();

        trackingAgent.track('checkout-view');
    }

    componentWillUnmount() {
        this.clearPaymentMethodCallbacks();
    }

    render() {
        const { currentEvent, onPaymentNotMade } = this.props;
        const {
            cardHolderFullName,
            cardHolderEmail,
            processing,
            disabled,
            succeeded,
            error,
            isFatalError,
            clientSecret,
            paymentRequest,
            ticketPrice
        } = this.state;
        
        const paymentRequestElement = paymentRequest ? (
            <React.Fragment>
                <div className="CheckoutForm-payment-request-button" style={{ marginTop: "1.5em", marginBottom: "3em" }}>
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                </div>
                <Divider horizontal>Or Pay with card</Divider>
            </React.Fragment>
        ) : null;

        const errorContent = isFatalError
            ? 'Sorry, there was a payment error. Please close this window and try again.'
            : error;

        const payButtonDisabled = processing || disabled || succeeded || !clientSecret || !ticketPrice;
        const payButtonAmount = ticketPrice ? `Pay ${ticketPrice.priceFormatted}` : '';
    
        return (
            <Form>
                <Modal open={true} size='small'>
                    <Modal.Header>{currentEvent.eventName}</Modal.Header>
                    <Modal.Content>
                        {paymentRequestElement}
                        <Header
                            as='h4'
                            content='Card details'
                        />
                        
                        <fieldset className='CheckoutForm-cardholder'>
                            <Input size='large'
                                placeholder="Full name"
                                fluid
                                type="text"
                                id="fullname"
                                name="fullname"
                                onChange={this.handleFullNameChange}
                                value={cardHolderFullName || ''} />
    
                            <Input size='large'
                                placeholder="Email"
                                fluid
                                type="text"
                                id="email"
                                name="email"
                                onChange={this.handleEmailChange}
                                value={cardHolderEmail || ''} />
                        </fieldset>
    
                        <CardElement id="card-element" options={CARD_OPTIONS} onChange={this.handleCardElementChange} />
                        <Message error
                            header='Payment Error'
                            content={errorContent}
                            hidden={!(errorContent && errorContent !== '')}
                            style={{ marginTop: "2em" }} />

                        <Button
                            type='submit'
                            loading={processing}
                            onClick={this.handlePayWithCardSubmit}
                            positive
                            fluid
                            style={{ marginTop: "2em" }}
                            disabled={payButtonDisabled}>{payButtonAmount}</Button>
                    </Modal.Content>
                    <Modal.Actions>
                        <Grid columns={2}>
                            <Grid.Column width={8} textAlign='left' verticalAlign="middle">
                                <Link to="/terms" target="_blank">See Terms</Link>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='right'>
                                <Button
                                    onClick={onPaymentNotMade}
                                    disabled={processing}>Cancel</Button>
                            </Grid.Column>
                        </Grid>
                    </Modal.Actions>
                </Modal>
            </Form>
        );
    }
}

export default CheckoutModal;