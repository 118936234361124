import React from 'react';
import {
    Container,
    Divider,
    Button,
    Loader,
    Modal,
    Embed,
    Segment,
    Header
} from 'semantic-ui-react';
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import MiniMasthead from '../components/MiniMasthead';
import MetaTags from '../components/MetaTags';
import CheckoutModal from '../components/CheckoutModal';
import Showtimes from '../components/Showtimes';
import ApiAuthErrorModal from '../components/ApiAuthErrorModal';
import { isCurentTimeWithinEventWindow, isEventWindowPast } from '../libs/dateTimeLib';
import { pricingManager } from '../libs/pricingManager';
import EventApiErrorModal from '../components/EventApiErrorModal';
import MailchimpModal from '../components/MailchimpModal';

import './EventPage.css';

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin',
        }
    ]
};

class EventPage extends React.Component {
    state = {
        currentEvent: null,
        isAuthenticated: this.props.authProvider.isAuthenticated(),
        isAuthorized: null,
        pagePath: null,
        showAlreadyAuthorizedModal: false,
        showCheckoutFormModal: false,
        showEmailReminderModal: false,
        authorizationError: null,
        eventApiError: null
    };

    async componentDidMount() {
        this.setAutheticationStateCallback();
        await this.initEventStatus();
    }

    componentWillUnmount() {
        this.clearAutheticationStateCallback();
    }

    setAutheticationStateCallback = () => {
        this.props.authProvider.setCallback(this.autheticationStateCallback);
    };

    clearAutheticationStateCallback = () => {
        this.props.authProvider.clearCallback(this.autheticationStateCallback);
    }

    autheticationStateCallback = async () => {
        await this.checkAuthorization(this.state.currentEvent);
    };

    purchaseButtonClick = async () => {
        await this.buttonClick(true);
    };

    havePurchaseButtonClick = async () => {
        await this.buttonClick(false);
    };

    buttonClick = async (requiresSignUp) => {
        const { authProvider } = this.props;
        const { isAuthenticated, currentEvent } = this.state;

        if (isAuthenticated) {
            this.setState({ showCheckoutFormModal: true });
            return;
        }

        const action = async () => {
            try {
                if (await this.getIsAuthorizedForEvent(currentEvent.eventId)) {
                    this.setState({ showAlreadyAuthorizedModal: true });
                } else {
                    this.setState({ showCheckoutFormModal: true });
                }
            } catch (error) {
                this.setState({ authorizationError: error });
            }
        };

        if (requiresSignUp) {
            authProvider.signup(async () => {
                await action();
            });
        }
        else {
            authProvider.login(async () => {
                await action();
            });
        }
    };

    proceedToLivePage = () => {
        const { currentEvent } = this.state;
        this.props.history.push('/live/' + currentEvent.urlSlug);
    };

    initEventStatus = async () => {
        const { functionsApiProvider } = this.props;
        const urlSlug = this.props.match.params.urlSlug;
        const pagePath = '/events/' + urlSlug;
        const status = { pagePath, eventApiError: null };

        try {
            status.currentEvent = await functionsApiProvider.getPublicEventByUrlSlug(urlSlug);
            await this.checkAuthorization(status.currentEvent);
        } catch (error) {
            status.eventApiError = error;
        }
        
        this.setState(status);
    };

    checkAuthorization = async (currentEvent) => {
        const { authProvider } = this.props;
        const isAuthenticated = authProvider.isAuthenticated();
        const status = { isAuthenticated, authorizationError: null };

        if (isAuthenticated) {
            try {
                status.isAuthorized = await this.getIsAuthorizedForEvent(currentEvent.eventId);
            } catch (error) {
                status.authorizationError = error;
            }
        }

        this.setState(status);
    };

    getIsAuthorizedForEvent = async (eventId) => {
        const { functionsApiProvider } = this.props;
        const authorizedEvent = await functionsApiProvider.getAuthorizedEventByEventId(eventId);
        return authorizedEvent != null && authorizedEvent.isPending === false;
    }

    getPurchaseButton = (isAuthenticated, isAuthorized, currentEvent, authorizationError) => {
        const ticketPrice = pricingManager.getTicketPriceForEvent();
        const defaultButtonText = `${ticketPrice.priceFormatted} - Buy Ticket`;

        if (isEventWindowPast(currentEvent)) {
            return { isPurchaseEnabled: false, purchaseTicketButton: (<Button positive size='huge' fluid disabled>Box Office Closed</Button>)};
        }

        if (authorizationError) {
            return { isPurchaseEnabled: false, purchaseTicketButton: (<Button positive size='huge' fluid disabled>{defaultButtonText}</Button>)};
        }

        if (!isAuthenticated || isAuthorized === false) {
            return { isPurchaseEnabled: true, purchaseTicketButton: (<Button positive size='huge' fluid onClick={this.purchaseButtonClick}>{defaultButtonText}</Button>)};
        }

        if (isAuthorized) {
            const isWithinEventWindow = isCurentTimeWithinEventWindow(currentEvent);
            const buttonText = isWithinEventWindow
                ? "Watch Now"
                : "Watch";

            return { isPurchaseEnabled: false, purchaseTicketButton: (<Button positive size='huge' fluid onClick={this.proceedToLivePage}>{buttonText}</Button>)};
        }

        return { isPurchaseEnabled: false, purchaseTicketButton: (<Button positive size='huge' fluid loading>{defaultButtonText}</Button>)};
    };

    getHavePurchaseButton = () => {
        return (<Button basic size='huge' fluid onClick={this.havePurchaseButtonClick}>Already Have Ticket</Button>);
    };

    yesPurchaseModalOption = () => {
        this.setState({ showAlreadyAuthorizedModal: false }, () => {
            this.proceedToLivePage();
        });
    };

    noPurchaseModalOption = () => {
        this.setState({ showAlreadyAuthorizedModal: false });
    };

    getPurchaseMadeModal = (showModal) => {
        return (
            <Modal open={showModal} size='tiny'>
                <Modal.Header>Live Event Purchase</Modal.Header>
                <Modal.Content>
                    <p>Great news! You already have a ticket for this event.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.noPurchaseModalOption}>Cancel</Button>
                    <Button positive onClick={this.yesPurchaseModalOption}>View</Button>
                </Modal.Actions>
            </Modal>
        );
    };

    showEmailReminderModal = () => {
        this.setState({ showEmailReminderModal: true });
    };

    hideEmailReminderModal = () => {
        this.setState({ showEmailReminderModal: false });
    };

    onPaymentComplete = () => {
        this.setState({ showCheckoutFormModal: false }, () => {
            this.proceedToLivePage();
        });
    };

    onPaymentNotMade = () => {
        this.setState({ showCheckoutFormModal: false });
    };

    getCheckoutFormModal = (currentEvent) => {
        const { stripePromise } = this.props;

        return (
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <CheckoutModal stripe={stripe} elements={elements}
                            {...this.props}
                            currentEvent={currentEvent}
                            onPaymentComplete={this.onPaymentComplete}
                            onPaymentNotMade={this.onPaymentNotMade} />
                    )}
                </ElementsConsumer>
            </Elements>
        );
    };

    render() {
        const {
            currentEvent,
            pagePath,
            isAuthenticated,
            isAuthorized,
            authorizationError,
            eventApiError,
            showAlreadyAuthorizedModal,
            showCheckoutFormModal,
            showEmailReminderModal
        } = this.state;
        const eventName = currentEvent ? currentEvent.eventName : '';

        const masthead = (<MiniMasthead text="Live from Van Gelder Studio" {...this.props}></MiniMasthead>);

        if (!currentEvent || eventApiError) {
            const eventApiErrorModal = eventApiError ? <EventApiErrorModal {...this.props} showModal={eventApiError !== null} /> : null;
            return (<React.Fragment>{masthead}<Loader active size="huge">Loading event...</Loader>{eventApiErrorModal}</React.Fragment>);
        }

        const haveTicketButton = this.getHavePurchaseButton();
        const { isPurchaseEnabled, purchaseTicketButton } = this.getPurchaseButton(isAuthenticated, isAuthorized, currentEvent, authorizationError);

        const purchaseMadeModal = showAlreadyAuthorizedModal ? this.getPurchaseMadeModal(showAlreadyAuthorizedModal) : null;
        const checkoutFormModal = showCheckoutFormModal ? this.getCheckoutFormModal(currentEvent) : null;
        const emailReminderModal = showEmailReminderModal ? <MailchimpModal {...this.props} onClose={this.hideEmailReminderModal} /> : null;
        const apiAuthErrorModal = authorizationError ? <ApiAuthErrorModal {...this.props} showModal={authorizationError !== null} /> : null;
        const ticketContainer = <Container align="center" style={{ marginTop: "2em", marginBottom: "3em" }} className="EventPage-button-container">
            {purchaseTicketButton}
            {!isAuthenticated && isPurchaseEnabled ?
                <React.Fragment><Divider horizontal>Or</Divider>{haveTicketButton}</React.Fragment> : null}
        </Container>;

        return (
            <React.Fragment>
                <MetaTags title={eventName} url={pagePath} />
                {masthead}

                <Container align="center" className="EventPage-headline-container" style={{ marginBottom: "3em" }}>
                    <Segment style={{ marginTop: "-45px" }}>
                        {ticketContainer}

                        <Header as='h1' content="Celebrate the Hammond Organ" style={{ fontSize: "3em" }}/>
                        <Header as='h2' content="with the one and only Joey DeFrancesco!" style={{ fontSize: "2em", marginTop: "-0.2em" }}/>

                        <p style={{ marginTop: "2em", fontSize: "1.2em" }}>Experience the unmistakable Van Gelder sound live from the best seat in your house.</p>

                        <Container align="center" className="EventPage-embed-container" style={{ marginTop: "3em", marginBottom: "2em" }}>
                            <Embed
                                id={currentEvent.vimeoId}
                                placeholder={currentEvent.imageUrl}
                                source='vimeo'
                                iframe={{
                                    allowFullScreen: true
                                }}
                            />
                            <p style={{ marginTop: "1em", fontSize: "1.2em" }}>Joey @ the rehearsal</p>
                        </Container>      
                    </Segment>
                </Container>

                <Container text textAlign='justified' style={{ marginTop: "2em" }}>
                    <p>A virtual concert series streamed LIVE from this historic Studio with legendary organist Joey DeFrancesco performing on the very organ that can be heard on countless iconic recordings made at the studio, including those by Ray Charles, Jimmy Smith &amp; Jack McDuff.</p>
                    <p>Also featuring Billy Hart, Peter Bernstein &amp; Houston Person.</p>
                </Container>

                <Container text textAlign='justified' style={{ marginTop: "3em" }}>
                    <Showtimes currentEvent={currentEvent} />
                </Container>

                <Container text textAlign='justified' style={{ marginTop: "2em", marginBottom: "3em" }}>
                    <p>Your ticket purchase on this page entitles you to view the Live performance or one of the two Restreams that suits your time zone.</p>
                    <p>Simply buy your ticket, and return to this web site with your log in to watch the show.</p>
                    <p>Thank you for joining us for this historic event.</p>
                </Container>

                {checkoutFormModal}
                {purchaseMadeModal}
                {apiAuthErrorModal}
                {emailReminderModal}
            </React.Fragment>
        );
    }
}

export default EventPage;
