import React from 'react';
import videojs from 'video.js'

import 'video.js/dist/video-js.css';
import '@silvermine/videojs-airplay/dist/silvermine-videojs-airplay.css';
import './Player.css';

require('@silvermine/videojs-airplay')(videojs);

class Player extends React.Component {

    toggleBigPlayButton = () => {
        if (this.player.paused()) {
            this.videoDivNode.classList.remove("vjs-has-started");
        } else {
            this.videoDivNode.classList.add("vjs-has-started");
        }
    }

    setupPlayer = (streamUrl) => {
        // this should only be performed once per app lifecycle
        this.props.registerVideoJSTech();

        const playerOptions = {
            techOrder: ["AmazonIVS"],
            controlBar: {
                playToggle: {
                    replay: false
                }, // Hides the replay button for VOD
                pictureInPictureToggle: false // Hides the PiP button
            }
        };

        this.player = videojs(this.videoNode, playerOptions, function onPlayerReady() {
            console.log('videojs onPlayerReady');
            this.enableIVSQualityPlugin();
            this.airPlay();
            this.src(streamUrl);
        });
    }

    async componentDidMount() {
        const { streamUrl, currentEventWindow, trackingAgent } = this.props;
        this.setupPlayer(streamUrl);

        if (currentEventWindow) {
            const { startTimeLocation, startTimeDescription } = currentEventWindow;
            trackingAgent.track('player-view', { startTimeLocation, startTimeDescription });
        }
    }

    componentWillUnmount() {
        if (this.player) {
            // not sure if this is needed
            //this.player.dispose()
        }
    }

    render() {
        return (
            <div data-vjs-player style={{ width: "80%" }} ref={node => this.videoDivNode = node} onClick={this.toggleBigPlayButton}>
                <video ref={node => this.videoNode = node}
                    controls
                    autoPlay
                    playsInline
                    className="video-js vjs-16-9 vjs-big-play-centered Player-player"
                    style={{ marginTop: "-45px" }}>
                </video>
            </div>
        );
    }
}

export default Player;