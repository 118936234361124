import React, { Component, Suspense, lazy } from "react";
import { Loader } from 'semantic-ui-react';
import { Switch, Redirect } from "react-router-dom";
import videojs from 'video.js'
import { loadStripe } from "@stripe/stripe-js";
import FullPageRoute from "./components/FullPageRoute";
import InnerPageRoute from "./components/InnerPageRoute";
import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./containers/HomePage";
import EventPage from "./containers/EventPage";
import LivePage from "./containers/LivePage";
import NotFoundPage from "./containers/NotFoundPage";
import LogIn from "./containers/LogIn";
import MetaTags from "./components/MetaTags";
import MiniMasthead from './components/MiniMasthead';
import { netlifyApiProvider } from './libs/netlifyApiProvider';
import { authenticationProvider } from './libs/netlifyAuthProvider';
import { trackingProvider } from './libs/heapTrackingProvider';
import { partnerManager } from './libs/partnerManager';

import './App.css';

// only needed once per app lifetime
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class App extends Component {

  registerVideoJSTech = () => {
    if (this.registerVideoJSTechCompleted) {
      return;
    }

    // scripts included in index.html
    const { registerIVSTech, registerIVSQualityPlugin } = window;

    registerIVSTech(videojs);
    registerIVSQualityPlugin(videojs);

    this.registerVideoJSTechCompleted = true;
    console.log('registerVideoJSTechCompleted');
  }

  componentDidMount() {
    authenticationProvider.setTracking(trackingProvider);
    partnerManager.setReferringPartnerSession();
  }

  render() {
    const childProps = {
      registerVideoJSTech: this.registerVideoJSTech,
      functionsApiProvider: netlifyApiProvider,
      stripePromise,
      trackingAgent: trackingProvider,
      authProvider: authenticationProvider
    };

    const termsPage = lazy(() => import("./containers/TermsPage"));
    const faqsPage = lazy(() => import("./containers/FAQsPage"));
    const testPage = lazy(() => import("./containers/TestPage"));
    const aboutPage = lazy(() => import("./containers/AboutPage"));

    const pageLoading = (
      <React.Fragment>
        <MetaTags title="Page Loading..." />
        <MiniMasthead text="Live from Van Gelder Studio" {...childProps}></MiniMasthead>
        <Loader active size="huge">Loading page...</Loader>
      </React.Fragment>
    )

    return (
      <div className="App">
        <Suspense fallback={pageLoading}>
          <Switch>
            {/* The versions of these redirects with query params are defined in the Netlify.toml file */}
            <Redirect from='/*/hank-mobely-tribute' to='/' />
            <Redirect from='/*/hank-mobley-tribute' to='/' />

            <FullPageRoute path="/" exact component={HomePage} props={childProps} />
            <InnerPageRoute path="/events/:urlSlug" exact component={EventPage} props={childProps} />
            <PrivateRoute path="/live/:urlSlug" exact component={LivePage} props={childProps} />
            <FullPageRoute path="/login" exact component={LogIn} props={childProps} />

            {/* Lazy-loaded inner pages */}
            <InnerPageRoute path="/faqs" exact component={faqsPage} props={childProps} />
            <InnerPageRoute path="/about" exact component={aboutPage} props={childProps} />
            <InnerPageRoute path="/video-playback-test" exact component={testPage} props={childProps} />
            <InnerPageRoute path="/video-stream" exact component={testPage} props={childProps} />
            <InnerPageRoute path="/terms" exact component={termsPage} props={childProps} />

            {/* End of the line */}
            <FullPageRoute component={NotFoundPage} props={childProps} />
          </Switch>
        </Suspense>
      </div>
    )
  }
}

export default App;
