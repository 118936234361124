import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Segment, Button } from 'semantic-ui-react';
import NavBar from "../components/NavBar";
import MetaTags from "../components/MetaTags";

import './FullPage.css';

class LogIn extends React.Component {
    state = { redirectToReferrer: false };

    showLogIn = () => {
        this.props.authProvider.login(() => {
            this.setState({ redirectToReferrer: true });
        });
    };

    componentDidMount() {
        if (!this.props.authProvider.isAuthenticated()) {
            this.showLogIn();
        }
    }

    render() {
        const { authProvider } = this.props;
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        const { redirectToReferrer } = this.state;

        if (redirectToReferrer || authProvider.isAuthenticated()) return <Redirect to={from} />;

        return (
            <React.Fragment>
                <MetaTags title='Log In' />
                <Segment
                    inverted
                    textAlign='center'
                    className="FullPage-masthead">
                    <NavBar hideAuthentication={true} {...this.props} />
                    <Container text style={{ marginBottom: "10em" }} className="FullPage-container">
                        <Button as="a" inverted size="huge" onClick={this.showLogIn}>
                            Log In / Sign Up
                        </Button>
                    </Container>
                </Segment>
            </React.Fragment>
        );
    }
}

export default LogIn;
