import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

export default function ApiAuthErrorModal(props) {

    const logout = () => {
        props.authProvider.signout(() => {
            props.history.push('/');
        });
    };

    return (
        <Modal open={props.showModal} size='tiny'>
            <Modal.Header>Authentication Error</Modal.Header>
            <Modal.Content>
                <p>Sorry, there was an error checking your logged in status. Please log back in and try again.</p>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={logout}>OK</Button>
            </Modal.Actions>
        </Modal>
    );
};