import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import NavBar from "../components/NavBar";
import MetaTags from "../components/MetaTags";

import './HomePage.css';

const HomepageHeading = () => (
    <Container text style={{ marginTop: '5em' }} className="HomePage-masthead-container">
        <Header
            as='h1'
            content="Live from Van Gelder Studio"
            inverted
            style={{
                fontSize: '4.3em',
                fontWeight: 'normal'
            }}
        />
        <Header
            as='h2'
            content='Stay tuned for future show announcements here'
            inverted
            style={{
                fontSize: '1.8em',
                fontWeight: 'normal',
                marginTop: '1.2em'
            }}
        />
        <Header
            as='h2'
            content='Thank you for your interest in the Van Gelder Studio'
            inverted
            style={{
                fontSize: '1.2em',
                fontWeight: 'normal',
                marginTop: '0.5em'
            }}
        />
    </Container>
)

export default function HomePage(props) {

    return (
        <React.Fragment>
            <MetaTags title='Home' />
            <Segment inverted
                textAlign='center'
                className="HomePage-masthead">
                <NavBar hideHomeLink={true} {...props} />
                <HomepageHeading />
            </Segment>
        </React.Fragment>
    );
};