import React, { useState, useEffect } from 'react';
import { Message } from 'semantic-ui-react';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';

function AnnouncementMessage() {
    const defaultMessageText = 'Service status & notifications will be posted here.';
    const defaultColor = 'grey';
    const defaultIcon = 'bullhorn';
    const [message, setMessage] = useState(defaultMessageText);
    const [icon, setIcon] = useState(defaultIcon);
    const [color, setColor] = useState(defaultColor);
    const pubnub = usePubNub();
    const channels = ['rvg_annoucements_channel'];

    useEffect(() => {
        const messageHandler = {
            message: messageEvent => {
                if (messageEvent.message) {
                    setMessage(messageEvent.message.text || defaultMessageText);
                    setIcon(messageEvent.message.icon || defaultIcon);
                    setColor(messageEvent.message.color || defaultColor);
                }
            }
        };

        pubnub.addListener(messageHandler);
        pubnub.subscribe({ channels });

        return () => {
            pubnub.unsubscribeAll();
            pubnub.removeListener(messageHandler);
        };

    }, [message, channels, pubnub]);

    return (<Message header='Notifications' content={message} color={color} icon={icon} />);
};

export default function Announcements({ authProvider }) {

    if (!authProvider.isAuthenticated()) {
        return (<React.Fragment></React.Fragment>);
    }

    const uuid = authProvider.currentUser().id;
    const pubnub = new PubNub({
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        uuid: uuid
    });

    return (
        <PubNubProvider client={pubnub}>
            <AnnouncementMessage />
        </PubNubProvider>
    );
};