import React from 'react';
import { Table, Divider, Header } from 'semantic-ui-react';
import { isCurentTimeWithinStreamWindow, isStreamWindowPast } from '../libs/dateTimeLib';

export default function Showtimes({ currentEvent }) {

    const tableHeadings = currentEvent.streamNames ?? ['Live Stream', '1st Restream', '2nd Restream'];
    const tableArray = ([currentEvent]).concat(currentEvent.restreams);

    const tableHeadingElements = () => {
        const streamHeadingElements = tableArray.map((item, index) => {
            return (<Table.HeaderCell textAlign='center' key={index}>{tableHeadings[index]}<Header as="h5" content={item.startTimeLocation} /></Table.HeaderCell>);
        });

        return streamHeadingElements;
    };

    const tableCellElements = () => {
        const streamCellElements = tableArray.map((item, index) => {
            const isStreamingNow = isCurentTimeWithinStreamWindow(item.startTimeUtc, currentEvent.durationInMinutes);
            const isPast = isStreamWindowPast(item.startTimeUtc, currentEvent.durationInMinutes);
            const disabled = (isPast && !isStreamingNow);
            return (<Table.Cell textAlign='center' key={index} positive={isStreamingNow} disabled={disabled}>{item.startDate} {item.startTime}</Table.Cell>);
        });

        return streamCellElements;
    }

    return (
        <React.Fragment>            
            <Divider horizontal>
                <Header as='h4'>
                    Showtimes
                </Header>
            </Divider>
            <Table celled padded unstackable fixed>
                <Table.Header>
                    <Table.Row>
                        {tableHeadingElements()}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        {tableCellElements()}
                    </Table.Row>
                </Table.Body>
            </Table>
        </React.Fragment>
    );
};