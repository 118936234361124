function identify(identity) {
    handleTrackingRequest(() => getHeap().identify(identity));
}

function addUserProperties(properties) {
    handleTrackingRequest(() => getHeap().addUserProperties(properties));
}

function resetIdentity() {
    handleTrackingRequest(() => getHeap().resetIdentity());
}

function track(eventName, properties) {
    handleTrackingRequest(() => getHeap().track(eventName, properties));
}

function handleTrackingRequest(request) {
    try {
        request();
    } catch (error) {
        console.log('Tracking not yet ready')
    }
}

function getHeap() {
    return window.heap;
}

export const trackingProvider = {
    identify,
    addUserProperties,
    resetIdentity,
    track
};