import React from 'react';
import { Button, Modal, Input, Message, Segment } from 'semantic-ui-react';

class ChangeEmailForm extends React.Component {
    state = {
        newEmailAddress: null,
        showEmailChangeFormModal: false,
        error: null,
        processing: false,
        succeeded: false
    };

    setSucceededStates = () => {
        this.setState({ succeeded: true, processing: false, error: null });
    };

    setErrorStates = (errorMessage) => {
        this.setState({ succeeded: false, processing: false, error: errorMessage || null });
    };

    changeEmail = async () => {
        const { newEmailAddress } = this.state;

        if (!newEmailAddress) {
            this.setErrorStates('A valid email address is required');
            return;
        }

        this.setState({ processing: true });

        try {
            const user = this.props.authProvider.currentUser();
            await user.update({ email: newEmailAddress });
        }
        catch (error) {
            this.setErrorStates('Sorry, we could not update your email. Please try again.');
            return;
        }
        
        this.setSucceededStates();
    };

    handleEmailInputChange = async (event, data) => {
        this.setState({ newEmailAddress: data.value, error: '' });
    };

    render() {
        const { onClose } = this.props;
        const { processing, succeeded, newEmailAddress, error } = this.state;

        const modalContent = !succeeded ? (<Modal.Content>
            <p>You will be sent a confirmation email to complete this update.</p>

            <Message error content={error} hidden={!(error && error !== '')} />

            <Input size='large'
                placeholder="Email"
                fluid
                type="text"
                id="email"
                name="email"
                onChange={this.handleEmailInputChange}
                value={newEmailAddress || ''} />

            <Button
                fluid
                onClick={this.changeEmail}
                loading={processing}
                disabled={processing}
                style={{ marginTop: "3em" }}
                color='black'>Update</Button>

            <Segment basic textAlign='center' style={{ marginTop: "0em" }}>
                <span onClick={onClose} style={{ cursor: "pointer" }}>Close</span>
            </Segment>
        </Modal.Content>) : (<Modal.Content>
            <p>Please check your email for your confirmation link.</p>

            <Button
                fluid
                onClick={onClose}
                style={{ marginTop: "3em" }}
                color='black'>Close</Button>
        </Modal.Content>);

        return (
            <Modal open={true} size='tiny'>
                <Modal.Header>Change Email Address</Modal.Header>
                {modalContent}
            </Modal>
        );
    };
}

export default ChangeEmailForm;