import moment from 'moment';

export function isCurentTimeWithinEventWindow(event) {
    const isWithinWindow = event.restreams.reduce((isWithinSoFar, currentItem) => {
        return (isWithinSoFar || isCurentTimeWithinStreamWindow(currentItem.startTimeUtc, event.durationInMinutes));
    }, isCurentTimeWithinStreamWindow(event.startTimeUtc, event.durationInMinutes));

    return isWithinWindow;
}

export function curentEventWindow(event) {
    if (isEventWindowPast(event)) {
        return null;
    };

    const currentWindow = event.restreams.reduce((startTimeSoFar, currentItem) => {
        return (isCurentTimeWithinStreamWindow(startTimeSoFar.startTimeUtc, event.durationInMinutes))
            ? startTimeSoFar
            : currentItem;

    }, event);

    return currentWindow;
}

export function isEventWindowPast(event) {
    const isPast = event.restreams.reduce((isPastSoFar, currentItem) => {
        return (isPastSoFar && isStreamWindowPast(currentItem.startTimeUtc, event.durationInMinutes));
    }, isStreamWindowPast(event.startTimeUtc, event.durationInMinutes));

    return isPast;
}

export function nextEventWindow(event) {
    if (isEventWindowPast(event)) {
        return null;
    };

    const nextWindow = event.restreams.reduce((startTimeSoFar, currentItem) => {
        const timeDifferenceSoFar = getTimeDifferenceInTotalMinutes(startTimeSoFar.startTimeUtc, event.durationInMinutes);

        // event has not yet started
        return (timeDifferenceSoFar > 0)
            ? startTimeSoFar
            : currentItem;

    }, event);

    return nextWindow;
}

export function isCurentTimeWithinStreamWindow(eventStartTimeUtc, eventDurationInMinutes) {
    const timeDifferenceInTotalMinutes = getTimeDifferenceInTotalMinutes(eventStartTimeUtc);

    // event has not yet started
    if (timeDifferenceInTotalMinutes > 0) {
        return false;
    }

    return (timeDifferenceInTotalMinutes + eventDurationInMinutes) > 0;
}

export function isStreamWindowPast(eventStartTimeUtc, eventDurationInMinutes) {
    const timeDifferenceInTotalMinutes = getTimeDifferenceInTotalMinutes(eventStartTimeUtc);

    // event has not yet started
    if (timeDifferenceInTotalMinutes > 0) {
        return false;
    }

    return -timeDifferenceInTotalMinutes > eventDurationInMinutes;
}

function getTimeDifferenceInTotalMinutes(eventStartTimeUtc) {
    const eventStartTimeMoment = new moment(eventStartTimeUtc);
    const utcNowMoment = new moment();

    const difference = moment.duration(eventStartTimeMoment.diff(utcNowMoment));
    return difference.asMinutes();
}