import React from 'react';
import { Container, Menu, Button, Dropdown, Header } from 'semantic-ui-react';
import ChangeEmailForm from './ChangeEmailForm'; 

import './NavBar.css';

class NavBar extends React.Component {
    state = {
        isAuthenticated: this.props.authProvider.isAuthenticated(),
        showEmailChangeFormModal: false
    };

    componentDidMount() {
        this.setAutheticationStateCallback();
    }

    componentWillUnmount() {
        this.clearAutheticationStateCallback();
    }

    setAutheticationStateCallback = () => {
        this.props.authProvider.setCallback(this.autheticationStateCallback);
    }

    clearAutheticationStateCallback = () => {
        this.props.authProvider.clearCallback(this.autheticationStateCallback);
    }

    autheticationStateCallback = () => {
        const isAuthenticated = this.props.authProvider.isAuthenticated();
        this.setState({ isAuthenticated: isAuthenticated });
    };

    login = () => {
        this.props.authProvider.login();
    };

    signup = () => {
        this.props.authProvider.signup();
    };

    logout = () => {
        this.props.authProvider.signout(() => {
            this.home();
        });
    };

    home = () => {
        this.props.history.push('/');
    };

    showChangeEmailForm = () => {
        this.setState({ showEmailChangeFormModal: true });
    };

    hideChangeEmailForm = () => {
        this.setState({ showEmailChangeFormModal: false });
    };

    render() {
        const { hideHomeLink, hideAuthentication, authProvider } = this.props;
        const { showEmailChangeFormModal } = this.state;

        const homeItem = hideHomeLink ? null : (<Menu.Item onClick={this.home}>
            <Header as="h3" inverted>Home</Header>
        </Menu.Item>);

        const trigger = <Button inverted>Account</Button>
        const emailAddress = authProvider.currentUser() ? authProvider.currentUser().email : '';

        const loggedIn = <Dropdown
            trigger={trigger}
            floating
            icon={null}
            style={{ marginLeft: '0.5em' }}
        >
            <Dropdown.Menu>
                <Dropdown.Header content={emailAddress} />
                <Dropdown.Divider />
                <Dropdown.Item text='Change Email' onClick={this.showChangeEmailForm} />
                <Dropdown.Item text='Log Out' onClick={this.logout} />
            </Dropdown.Menu>
        </Dropdown>;

        const loggedOut = (<React.Fragment>
            <Button inverted onClick={this.login}>
                Log In
            </Button>
            <Button inverted style={{ marginLeft: '0.5em' }} onClick={this.signup}>
                Sign Up
            </Button>
        </React.Fragment>);

        const menuItems = hideAuthentication ? null : (this.props.authProvider.isAuthenticated() ? loggedIn : loggedOut);
        const changeEmailFormModal = showEmailChangeFormModal ? <ChangeEmailForm onClose={this.hideChangeEmailForm } {...this.props} /> : null;

        return (<Menu inverted pointing text className="NavBar">
            <Container className="NavBar-container">
                {homeItem}
                <Menu.Menu position='right'>
                    {menuItems}
                </Menu.Menu>
            </Container>
            {changeEmailFormModal}
        </Menu>);
    }
}

export default NavBar;