import React from 'react';
import { Container, Divider, Header, Loader, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MiniMasthead from '../components/MiniMasthead';
import MetaTags from "../components/MetaTags";
import Player from '../components/Player';
import StreamCountdown from '../components/StreamCountdown';
import Showtimes from '../components/Showtimes';
import {
    isCurentTimeWithinEventWindow,
    isEventWindowPast,
    nextEventWindow,
    curentEventWindow } from '../libs/dateTimeLib';
import Announcements from '../components/Announcements';
import EventApiErrorModal from '../components/EventApiErrorModal';

import 'video.js/dist/video-js.css';

class LivePage extends React.Component {
    state = {
        currentEvent: null,
        streamUrl: null,
        isAuthenticated: this.props.authProvider.isAuthenticated(),
        pagePath: null,
        isCountingDown: false,
        isPendingAuthorization: true,
        eventApiError: null
    };

    setAutheticationStateCallback = () => {
        this.props.authProvider.setCallback(this.autheticationStateCallback);
    }

    clearAutheticationStateCallback = () => {
        this.props.authProvider.clearCallback(this.autheticationStateCallback);
    }

    autheticationStateCallback = () => {
        this.setState({ isAuthenticated: this.props.authProvider.isAuthenticated() })
    };

    proceedToEventsPage = (currentEvent) => {
        this.props.history.push('/events/' + currentEvent.urlSlug);
    };

    initEventStatus = async () => {
        const { functionsApiProvider } = this.props;
        const urlSlug = this.props.match.params.urlSlug;
        const pagePath = '/events/' + urlSlug;
        const status = { pagePath, eventApiError: null };

        try {
            status.currentEvent = await functionsApiProvider.getPublicEventByUrlSlug(urlSlug);

            if (isEventWindowPast(status.currentEvent)) {
                this.proceedToEventsPage(status.currentEvent);
                return;
            }
        } catch (error) {
            status.eventApiError = error;
        }

        if (status.currentEvent) {
            try {
                await this.setPlayerState(functionsApiProvider, status.currentEvent);
            } catch (error) {
                this.proceedToEventsPage(status.currentEvent);
                return;
            }
        }
        
        this.setState(status);
    };

    setPlayerState = async (functionsApiProvider, currentEvent) => {
        const authorizedEventData = await functionsApiProvider.getAuthorizedEventByEventId(currentEvent.eventId);

        if (!authorizedEventData) {
            throw Error('Not authorized for this event');
        }

        const isWithinEventWindow = isCurentTimeWithinEventWindow(currentEvent);

        if (
            isWithinEventWindow
            && authorizedEventData.isPending === false
            && authorizedEventData.protectedData && authorizedEventData.protectedData.streamUrl) {
            // watch the show!
            this.setState({
                streamUrl: authorizedEventData.protectedData.streamUrl,
                isCountingDown: false,
                isPendingAuthorization: false
            });
            return;
        }

        this.setState({ isCountingDown: !isWithinEventWindow, isPendingAuthorization: authorizedEventData.isPending });
        this.startCheckingPlayerState();
    };

    checkPlayerState = async () => {
        const { functionsApiProvider } = this.props;
        const { currentEvent } = this.state;

        try {
            await this.setPlayerState(functionsApiProvider, currentEvent);
        } catch (error) {
            this.proceedToEventsPage(currentEvent);
        }
    };

    startCheckingPlayerState = () => {
        this.stopCheckingPlayerState();
        this.timerId = setTimeout(this.checkPlayerState, 10000);
    }

    stopCheckingPlayerState = () => {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    async componentDidMount() {
        this.setAutheticationStateCallback();
        await this.initEventStatus();
    }

    componentWillUnmount() {
        this.clearAutheticationStateCallback();
        this.stopCheckingPlayerState();
    }

    render() {
        const {
            currentEvent,
            streamUrl,
            pagePath,
            isPendingAuthorization,
            isAuthenticated,
            isCountingDown,
            eventApiError
        } = this.state;
        const eventName = currentEvent ? currentEvent.eventName : '';

        const masthead = (<React.Fragment>
            <MetaTags title={eventName} url={pagePath} />
            <MiniMasthead text="Live from Van Gelder Studio" {...this.props}></MiniMasthead>
        </React.Fragment>);

        if (!currentEvent || eventApiError) {
            const eventApiErrorModal = eventApiError ? <EventApiErrorModal {...this.props} showModal={eventApiError !== null} /> : null;
            return (<React.Fragment>{masthead}<Loader active size="huge">Loading event...</Loader>{eventApiErrorModal}</React.Fragment>);
        }

        if (!isAuthenticated || (!isCountingDown && isPendingAuthorization)) {
            return (<React.Fragment>{masthead}<Loader active size="huge">Checking authorization...</Loader></React.Fragment>);
        }

        if (!streamUrl && !isCountingDown) {
            return (<React.Fragment>{masthead}<Loader active size="huge">Waiting for event...</Loader></React.Fragment>);
        }

        return (
            <React.Fragment>
                {masthead}

                <Container align="center">
                    {streamUrl ? <Player streamUrl={streamUrl} currentEventWindow={curentEventWindow(currentEvent)} {...this.props} /> : null}
                    {isCountingDown ? <StreamCountdown
                        nextEventWindow={nextEventWindow(currentEvent)}
                        onCompletion={this.checkPlayerState} {...this.props} /> : null}
                </Container>

                <Container style={{ marginTop: "2em" }}>
                    {streamUrl ? <Announcements {...this.props} /> : null}
                </Container>

                {isPendingAuthorization ?
                    <Container text textAlign='justified' style={{ marginTop: "3em" }}>
                        <Message warning>
                            <Message.Content>
                                <p>Your ticket is being prepared. You will receive a confirmation email when ready.</p>
                            </Message.Content>
                        </Message>
                    </Container> : null}

                {isCountingDown ?
                    <Container text textAlign='justified' style={{ marginTop: "3em" }}>
                        <p>Bookmark this web page and watch the countdown to the performance. Live streams and restreams <strong>will play right here!</strong></p>
                        <p>Be sure to have your Log In information at show time.</p>
                    </Container> : null}

                <Container text textAlign='justified' style={{ marginTop: "2em" }}>
                    <Showtimes currentEvent={currentEvent} />
                </Container>

                <Container text textAlign='justified' style={{ marginTop: "2em" }}>
                    <Divider horizontal>
                        <Header as='h4'>
                            How to Watch
                        </Header>
                    </Divider>
                    Please visit our <Link to="/faqs">FAQs</Link> for tips on optimizing your viewing experience.
                </Container>
            </React.Fragment>
        );
    }
}

export default LivePage;