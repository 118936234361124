import React from "react";
import { Helmet } from "react-helmet";

class MetaTags extends React.Component {

    getCanonicalLink = (path) => {
        return process.env.REACT_APP_WEBSITE_URL + path;
    };

    render() {
        const props = this.props;

        const title = (props.title ? props.title + ' | ' : null) + 'Van Gelder Live';
        const description = props.description || "Live from Van Gelder Studio is a series of live-stream performances capturing the unmistakable Van Gelder sound in the same room, on the same piano, and using the same microphones that were used to capture important recordings such as John Coltrane's “A Love Supreme”, Herbie Hancock's “Maiden Voyage”, Horace Silver's “Song for My Father” – and hundreds of others.";
        const type = props.type || 'website';
        const image = props.image || "https://res.cloudinary.com/van-gelder-studio/image/upload/f_auto,q_auto:best,c_scale,h_540/v1618364735/the-van-gelder-organ/joey-defrancesco-post-v2_y6band.png";
        const url = props.url ? this.getCanonicalLink(props.url) : this.getCanonicalLink('/');

        return (
            <Helmet>
                <title>{title}</title>
                <link rel="canonical" href={url} />
                {!description ? null : (<meta name="description" content={description} />)}
                <meta property="og:url" content={url} />
                <meta property="og:type" content={type} />
                <meta property="og:title" content={title} />
                {!description ? null : (<meta property="og:description" content={description} />)};
                {!image ? null : (<meta property="og:image" content={image} />)};
            </Helmet>
        )
    }
}

export default MetaTags;