import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

export default function EventApiErrorModal(props) {

    const backToHomepage = () => {
        props.history.push('/');
    };

    return (
        <Modal open={props.showModal} size='tiny'>
            <Modal.Header>Event Request Error</Modal.Header>
            <Modal.Content>
                <p>Sorry, there was an error retrieving the event. Please try again.</p>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={backToHomepage}>OK</Button>
            </Modal.Actions>
        </Modal>
    );
};