import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import RouteNavButton from "../components/RouteNavButton";
import NavBar from "../components/NavBar";
import MetaTags from "../components/MetaTags";

import './FullPage.css';

export default function NotFoundPage(props) {
    return (
        <React.Fragment>
            <MetaTags title='Page Not Found' />
            <Segment
                inverted
                textAlign='center'
                className="FullPage-masthead">
                <NavBar hideAuthentication={true} {...props} />
                <Container text style={{ marginBottom: "10em" }} className="FullPage-container">
                    <Header
                        as='h1'
                        content='Page Not Found'
                        inverted
                        style={{
                            fontSize: '3em',
                            fontWeight: 'normal'
                        }}
                    />
                    <RouteNavButton as="a" inverted size="huge" href="/" style={{ marginTop: "2em" }}>
                        Take Me Home
                    </RouteNavButton>
                </Container>
            </Segment>
        </React.Fragment>
    );
};