import React from 'react';
import AppliedRoute from './AppliedRoute';
import Footer from "./Footer";

export default (props) => (
    <React.Fragment>
        <div className="App-content-fullpage">
            <AppliedRoute {...props} />
        </div>
        <Footer condensed={true} />
    </React.Fragment>
);
